  @font-face {
    font-family: 'SofiaPro-Light';
    src: url('./assets/fonts/SofiaProLight.woff2') format('woff2'),
        url('./assets/fonts/SofiaProLight.woff') format('woff'),
        url('./assets/fonts/SofiaProLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SofiaPro-Regular';
    src: url('./assets/fonts/SofiaProRegular.woff2') format('woff2'),
        url('./assets/fonts/SofiaProRegular.woff') format('woff'),
        url('./assets/fonts/SofiaProRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@media screen and (min-width: 1440px){
	.homeContainer {
		max-width: 1440px!important;
		width: 1440px;
	}
}

.nav-item> .nav-link {
    color: #0D1120;
    font-size: 18px;
	font-weight:400;
	font-family: 'SofiaPro-Regular';
}
.ms-auto.navutility.navbar-nav {
    margin-top: 10px;
}

a.link_nav.nav-link {
	color: #000000 !important;
    font-weight:400;
	font-size: 18px;	
}

.accordion-button:focus {
    box-shadow: none !important;
    outline: none !important;
}
.tabsClass{
	font-family: sofia-pro, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px !important;
    line-height: 150%;
}

.font-weight-bold {
    font-weight: 700!important;
	font-family: 'SofiaPro-Regular';
	font-size: 2rem;
	letter-spacing: .02em;
}


html {
  scroll-behavior: smooth;
  overflow: auto;
}
body{
	font-family: 'SofiaPro-Regular';
	font-smoothing: antialiased;
  webkit-font-smoothing: antialiased;
  font-feature-settings: "kern","liga","clig","calt";
  font-kerning: normal;
}

body nav.primary {
 	display: flex;
  justify-content: space-between;
  margin: 0 1rem;
  padding: 0px 15px 0;
  align-items: center;
}
nav a, .nav-link {
  color: #0e1120;
  font-weight: 400;
  font-size: 18px;
  font-style: normal;
}

nav ul {
  align-items: center;
  display: flex;
  gap: 40px;
  height: 100%;
  justify-content: center;
  list-style: none;
  margin-top: 6px;
  padding-top: 20px;
}


a, h1, h2, h3, h4, h5, label, p {
    font-family: SofiaPro-Regular,sans-serif;
    font-style: normal;
}
.mobile_homebtn{
		display: none;
	}
	.web_home_btn{
		display: block;
	}
	.margin_web{
		margin: 0 15px;
	}


@media screen and (max-width: 576px) {
	
	.applynow{
			font-size:36px !important;
	}
	.logo_class>img{
		padding-left:0 !important;
		padding-right:0 !important;
		margin-left:0 !important;
		margin-right:0 !important;
	}
	.footer_copyright{
		padding-left:0 !important;
		padding-right:0 !important;
		margin-left:0 !important;
		margin-right:0 !important;
	}
	#contactForm{
		padding-right:0 !important;
		margin-right:0 !important;
	}
	.all_main_heads{
		font-size:30px !important;
	}
	.mob_enrol_title{
		font-size:24px !important;
	}
	.mob_enrol_img{
		width:24px;
	}
	.enrol_pad{
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	.intro{
		padding-bottom: 0px !important;
	}
	.intro_heading{
		font-size:36px !important;
	}
	.compass_bottom{
		margin: 0 !important;
	}
	.margin_web{
		margin: 0;
	}
	.mobile_homebtn{
		display: block;
	}
	.web_home_btn{
		display: none;
	}
	.redbtn{
		display: inline !important;
	}
	.logo_class{
	background: #fff;
   
    margin: 0 !important;

	}
	.margin_4{
		margin-bottom: 8px;
		padding-bottom: 8px;
	}
	.mobilenav{
		margin:0;
		padding:0;
		background-color: #fff;
	}
	.navutility{
		padding:15px;
	}
	.navutility>li:nth-child(2){
		padding-bottom:12px;
	}
	.space_0{
		padding: 0 !important;
		margin: 0 !important;
	}
	.space_top_4{
		padding: 8px !important;
		margin: 8px !important;
	}
	.space_bottom_4{
		padding-bottom: 8px !important;
		padding-top: 0 !important;
	}
	.margin_0{
		margin: 0 !important
	}
	.padding_0{
		padding: 0 !important;
	}
	.height_footer{
		height: auto !important;
		
	}
	.footer_copyright{
		width:100% !important;
		position: static !important;
	}
}
.height_footer{
		height: 40px;
	}
body{
	overflow: hidden;
}
h1,h2,h3,h4,h5{
	font-family: SofiaPro-Regular ;
	font-style: normal;
	font-weight: bold;
	line-height: 109%;
	letter-spacing: 0.02em;
}
p,a,label{
	font-family: SofiaPro-Regular ;
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 28px;
	text-decoration: none!important;
}
h2{
	font-family: SofiaPro-Regular ;
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 39px !important;
	line-height: 120% !important;
}
h3{
	font-size: 30px !important;
}
h4{
	font-family: SofiaPro-Regular ;
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 28px !important;
	line-height: 33px !important;
	color: #103151;
}
.h1,h1{
	font-family: SofiaPro-Regular ;
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 53px !important;
	line-height: 120% !important;
}
.learnmore{
	color: #fff !important;
}
.learnmore:hover{
	text-decoration: none;
	color: #0D1120 !important;
	
}
.footerbtn:hover {
	color:#D9000D !important;
	text-decoration: underline !important;
}
.form_heads{
	font-family: SofiaPro-Regular ;
	font-style: normal;
	font-weight: bold;
	font-size: 31px !important;
	line-height: 36px;
	
	color: #0D1120;
}
.redfont{
	font-family: SofiaPro-Regular ;
	font-style: normal;
	font-weight: 800;
	font-size: 22px;
	line-height: 28px;
	color: #D9000D;
}
.homenav:hover{
	color: #FFFFFF;
}
header li.nav-item {
  padding: 0 15px;

}
header li.nav-item a {
	font-weight: normal;
}
/* Typography code ends here */
/* Header section code starts here */

.navbar-brand.logo_className {
	padding-top: 16px;
	padding-bottom: 17px;
}
.bg_banner_sec{
	background-image: url(./assets/images/home-banner-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
	background-position: center;
	width:100%;
}
.bg_banner_sec_2{
	background-image: url(./assets/images/home-banner-bg-2.svg);
  background-repeat: no-repeat;
  background-size: cover;
	background-position: center;
	width:100%;
	padding-top: 30px;
	padding-bottom: 70px;
}
.intro{
	height: auto;
	display: flex;
	flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	align-content: center;
}
.intro_heading{
	
	font-family: SofiaPro-Regular,sans-serif !important;
	font-style: normal;
	letter-spacing: 0.02em;
	color: #FFFFFF;
	font-size: 60px;
    font-weight: 800;
    line-height: 109%;  
    margin: 10px 0 20px;

}
.intro_heading1{
	
	font-family: SofiaPro-Regular,sans-serif !important;
	font-style: normal;
	letter-spacing: 0.02em;
	color: #FFFFFF;
	font-size: 53px;
    font-weight: 800;
    line-height: 109%;   
}
.intro_para{
	font-family: SofiaPro-Regular ;
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 28px;
	color: #FFFFFF;
    line-height: 1.5em;
}
footer .intro_para a {
	color: #D9000D;
}
header nav {
	  padding: 0px 20px 10px;
}
nav a.black_text:hover {
  color: #0056b3;
  text-decoration: underline!important;
}
.redbtn{
	background: #D9000D;
	box-sizing: border-box;
	border-radius: 5px;
	color: #FFFFFF !important;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px;
	font-family: SofiaPro-Regular ;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 28px;
	outline: none;
    border: none;
}
.redbtn_see:hover {
  background: #0d1120;
}
div.program_intro {
  /*margin-top: -60px;*/
  background: #fff;
  padding: 20px 40px;
  border-radius: 10px 10px 0 0;
/*  box-shadow: 5px -195px 25px 3px #002f5942;*/
}
.compass_bottom{
	font-family: SofiaPro-Regular ;
	font-style: normal;
	font-weight: 900;
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0.02em;
	display: block;
}
.compass_bottom>p{
	font-family: SofiaPro-Regular ;
	font-style: normal;
	font-weight: 900;
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}
.applynow{
	font-family: SofiaPro-Regular ;
	font-style: normal;
	font-weight: bold;
	font-size: 69px;
	line-height: 105%;
	text-align: center;
	color: #0D1120;
}
.red_text{
	color: #E93742;
}
.white_text{
	color: #FFFFFF;
}
.black_text{
	color: #0D1120;
}
.bg_footer{
	background: #0D1120;
}
.bg_navy{
	background: #103151;
}
.bg_lightblue{
	background:  #044989;
}
.bg_blue{
	background: #2B4F70;
}
.bg_skyblue{
	background: #CAE1F4;
}
footer h2 {
	color:  #fff;
}
.footer_copyright{
	font-family: SofiaPro-Regular ;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 150%;
	color: #FFFFFF;
}
.footer_copyright>p,.footer_copyright>p>a{
	font-size: 14px;
}
.redbtn_see{
	background: #D9000D;
	box-sizing: border-box;
	border-radius: 5px;
	color: #FFFFFF;
	padding: 9px 15px 15px;
	font-family: SofiaPro-Regular ;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 28px;
	display: inline-block;
	outline: 0;
	border: 0;
}
.redbtn_see:hover {
	text-decoration: none;
  background: #0d1120;
  color: #fff;
}
.bg_img{
	background-image: url("./assets/images/Rectangle 1.png");
	background-repeat: no-repeat;
	background-size: auto;
    background-position: bottom right;
}
.bg_footer{
	background: #0D1120;
	position: relative;
	padding: 20px 0;
}
.bg_img_2{
	background-image: url("./assets/images/Group 92.png");
	background-repeat: no-repeat;
	background-size: auto;
   background-position: bottom;
}

.contactus>li>a{
	font-size: 14px;
}
.footerImg {
	position: absolute;
	bottom: 60px;
	right: 60px;
}
@media screen and (max-width: 576px) {
	
	.tabsClass{
		font-size: 14px !important;
	}

	.footerImg{
		position: static;
		top:none;
		left:none;
	}
	.bg_footer{
		padding:0;
	}
	h4{

		font-size: 18px !important;

	}
}
/* Header section code ends here */