@font-face {
	font-family: sofia-pro, sans-serif;
	src: url(./../../assets/fonts/sofiapro-light.otf);
  }
div[aria-expanded="true"] .rotate-icon{
    transform: rotate(180deg);
}
.w-80{
	width: 80%;
}
.rotate-icon{
    float: right;
    position: relative;
    top: 6px;
}
.pr-schedule{
    border: none;
    margin-bottom: 30px;
}
.pr-schedule .card-header{
    padding: 0;
    margin-bottom: 0 !important;
    border: none;
    background: none;
}
.pr-schedule .card-header a:hover{
    text-decoration: none;
}
.pr-schedule .card-header h4{
    padding: 30px;
}
.tab_head{
	font-family: 'SofiaPro-Regular';
	font-style: normal;
	font-weight: bold !important;
	font-size: 29px !important;
	line-height: 38px;
	color: #0D1120;
	cursor: pointer;
	padding: 30px;
}
@media screen and (max-width: 576px) {
	.tab_head{
	font-size: 16px !important;
	line-height: 24px !important;
}
.rotate-icon{
		top: 2px !important;
	}
.pr-schedule .card-header h4 {
    padding: 12px !important;
}
.bg-dark-blue {
    padding: 12px 0px !important;
}
.padding_4{
	padding: 12px !important;
}
}
.pr-schedule .card-header .bg-blue h4{
    color: #ffffff;
}
.bg-dark-blue{
    background: #103151;
    padding: 25px 50px;
}
.bg-blue{            
    background: #2B4F70;
}
.pr-nav .nav-link{
    border-radius: 5px 5px 0 0;
    font-size: 18px;
    font-weight: 600;
    padding: 20px;
    color: #0d1120;
}
.pr-nav .nav-item:first-child{
    margin-right: 5px;
}
.pr-nav .nav-link.active, .pr-nav .show>.nav-link {
    color: #fff;
    background-color: #103151;
}
.nav-pills .nav-item {
    background: #CAE1F4;
    border-radius: 5px 5px 0 0;            
}
.n_h{
	width:236px; 
	height:233px;
	margin-top:25px;
	float:right;
}
.program_breakdown{
	font-size:69px;
	font-weight:bold;
	font-family: sofia-pro, sans-serif;
}
.the_programme{
	font-size:18px;
	font-weight:400;
	color:#0D1120; 
	margin-top:20px;
	font-family: sofia-pro, sans-serif;
}
.sep_6th{
	font-weight:800;
	font-size:22px;
	color:#D9000D;
	font-family: sofia-pro, sans-serif;
}
.Participants{
	font-weight:700;
	font-size:17px;
}
.tab_text{
	font-family: sofia-pro, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 22px !important;
	line-height: 150%;
}
.accordion-button{
	padding: 0;
}
.accordion-button::after{
	background-image: url(../images/angle-down-solid.svg);
	background-size: 30px;
	width: 30px;
	height: 30px;
	position: absolute;
    top: 30%;
    right: 10px;
	/* transform: translateY(-50%); */
}
.accordion-button:not(.collapsed)::after {
    background-image: url(../images/angle-up-solid.svg);
    transform:  rotate(180deg);

}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
	background-color: #103151;
	color: #fff;
}
:focus{
	outline: 0;
	border:0;
}

@media screen and (max-width: 600px) {
	.accordion-button {
		padding: 0 0 0 0 !important;
	}
	.tab_head{
		padding:4px;
	}
	.accordion-button::after{
		background-size: 18px;
		width: 20px;
		height: 20px;
	}
	.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
		height: 100%;
	}
	.w-80{
		width: 100%;
	}
  }